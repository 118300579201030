<template>
    <div>
        <b-card>
            <app-view-table :title="trans('settings-documents-report', 167)" :isTitleTotal="true" :fields="fields" :items.sync="items" :filters="filters" :isFilterOptions="true" :filterOptions="list_type_options" :isMenuColumn="true" :loading="loading_list">
                
                <template v-slot:menu-items="cell">
                    <b-dropdown-item @click="openProfile(cell.data.item)"><span v-if="filters.list_type == 1">{{trans('to-flexworker',175)}}</span><span v-else-if="filters.list_type == 2">{{trans('to-organization',175)}}</span></b-dropdown-item>
                </template>

                <template v-slot:buttons>
                    <app-button type="primary" :loading="loading_download" @click="downloadList">{{trans('settings-documents-report-export', 206)}}</app-button>
                </template>

            </app-view-table>
        </b-card>
    </div>
</template>
<script>
import axios from "@axios";
import moment from "moment";

export default {
    
    components: {
        
    },

    props: {

    },

    mounted() {
        this.onCreateThis();
    },

    created() {
    },

    data()  {
        return{
            loading_list: false,
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering:{
                    dates: { type: 'dates', changed: 0, values: [],  function: "", parameters: {type: null}, widthClass:'app-width-min-350'},
                    statuses_person: {type: 'list', changed: 0, values: [], function: "getPersonSignDocumentStatus", parameters: null, widthClass:'app-width-min-350', getList: true},
                    types_person: {type: 'list', changed: 0, values: [], function: 'getPersonSignDocumentTypes', parameters: null, widthClass:'app-width-min-250', getList: true},
                    statuses_client: {type: 'list', changed: 0, values: [], function: "getClientSignDocumentStatus", parameters: null, widthClass:'app-width-min-350', getList: true},
                    types_client: {type: 'list', changed: 0, values: [], function: 'getClientSignDocumentTypes', parameters: null, widthClass:'app-width-min-250', getList: true}
                },
                page: 1,
                per_page: 20,
                search: "",
                list_type: 1,
                changed: 0,
            },
            fields:[
                { key: "document_type", label: this.trans('document-type',182), tdClass: "py-0 app-local-max-width", visible: true, thStyle: { "min-width": "200px", "max-width": "200px" }, filtering: true, filtering_name: "types_person"},
                { key: "entry_date", label: this.trans('entry-date',182), visible: true, thStyle: { "min-width": "120px", "max-width": "120px" }, filtering: true, filtering_name: "dates" },
                { key: "status", label: this.trans('status',3), visible: true, thStyle: { "min-width": "120px", "max-width": "120px" }, filtering: true, filtering_name: "statuses_person" },
                { key: "client_name", label: this.trans('core-client',3), visible: true, thStyle: { "min-width": "150px", "max-width": "150px" } },
                { key: "person_name", label: this.trans('flexworker', 182), visible: true, thStyle: { "min-width": "150px", "max-width": "150px" } },
            ],
            items: [],
            selected_ids: [],
            select_all: "0",
            time: null,
            list_type_options: [
                {value: 1, name: this.trans('employee', 5)},
                {value: 2, name: this.trans('client', 5)},
            ],
            loading_download: false
        }
    },

    methods: {

        onCreateThis() {
            this.setVisible();
            this.getList();

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.list_type',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.setVisible();
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.statuses_person.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                } 
            });

             this.$watch('filters.filtering.types_person.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.statuses_client.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.types_client.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.dates.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                } 
            });
        },

        getList(){
           this.loading_list = true;
            
           axios
                .post("settings/documentsReport/getList", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {                  
                    
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });
        },

        downloadList(){
            this.loading_download = true;
            axios
                .post("settings/documentsReport/downloadList", {
                    filters: JSON.stringify(this.filters),
                },
                {
                    responseType: 'blob', 
                    crossDomain: true,
                })
                .then((res) => {                 
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Documenten_verslag ' + moment().format('DD-MM-YYYY - HHmmss') +'.xlsx');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_download = false;
                });
        },

        setVisible(){
            if(this.filters.list_type == 1){
                this.fields[3].visible = false;
                this.fields[4].visible = true;

                this.fields[0].filtering_name = "types_person";
                this.fields[2].filtering_name = "statuses_person";

            } else if(this.filters.list_type == 2){
                this.fields[3].visible = true;
                this.fields[4].visible = false;

                this.fields[0].filtering_name = "types_client";
                this.fields[2].filtering_name = "statuses_client";

            } else if(this.filters.list_type == 3){
                this.fields[3].visible = true;
                this.fields[4].visible = true;
            }
        },

        openProfile(item){
            if(this.filters.list_type == 1){
                this.$router.push({name: 'people-person-profile', params: {id_person_data_main: item.id_person_data_main}});
            } else if(this.filters.list_type == 2){
                this.$router.push({name: 'clients-profile-organization', params: {id_client_data_main: item.id_client_data_main}});
            }
        }

    },
    
}
</script>
<style scoped>

</style>